/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PageLayout from "examples/LayoutContainers/PageLayout";

function Confirmation() {

    return (
        <PageLayout>
            <div style={{ width: "100%", textAlign: "center", fontSize: "20px", marginTop: "15%" }}>
                <p>Please confirm Your mail and then <a href="/login" style={{color:"blue"}}>Login Here</a></p>
            </div>
        </PageLayout>
    );
}

export default Confirmation;
