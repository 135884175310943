/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import { styled, makeStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import SearchIcon from "@mui/icons-material/Search";
// import DirectionsIcon from "@mui/icons-material/Directions";
import WalletIcon from "../../assets/images/wallet.png";
import LedgerIcon from "../../assets/images/ledger_logo.png";
import CalenderIcon from "../../assets/images/calender.png";
import CardIcon from "../../assets/images/card.png";
import { useNavigate } from "react-router-dom";
import { style } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

function eth2() {
  // const { columns, rows } = authorsTableData();
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [eth1Btn, setEth1Btn] = React.useState(false);
  const [eth2Btn, setEth2Btn] = React.useState(false);
  const [useETH1AddressBorder, setUseETH1AddressBorder] = React.useState(false);
  const [useETH2AddressBorder, setUseETH2AddressBorder] = React.useState(false);

  const [monthlyPlanBorder, setMonthlyPlanBorder] = React.useState(false);
  const [oneTimeBorder, setOneTimeBorder] = React.useState(false);

  const [payWithCardBorder, setPayWithCardBorder] = React.useState(false);
  const [payWithCryptoBorder, setPayWithCryptoBorder] = React.useState(false);

  React.useEffect(() => {}, [eth1Btn, eth2Btn, useETH1AddressBorder, useETH2AddressBorder]);
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // }));

  let navigate = useNavigate();
  React.useEffect(() => {
    // if (!sessionStorage.getItem("access_token")) {
    //   navigate("/login");
    // }
  }, []);

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Accordion expanded>
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: "#F0AA04", color: "white" }}
                expandIcon={<ExpandMoreIcon color="white" />}
              >
                <Typography style={{ color: "#FFFFFF" }}>Step 1: ETH2 Validators</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "14px" }}>
                  <p>How much ETH do you want to deposit?</p>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      width: "100%",
                    }}
                    style={{ marginTop: "15px" }}
                  >
                    {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
                      <MenuIcon />
                    </IconButton> */}
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="0"
                      inputProps={{ "aria-label": "0" }}
                    />
                    <IconButton type="submit" sx={{ p: "12px" }} aria-label="search">
                      <p style={{ fontSize: "12px" }}>$0.00</p>
                    </IconButton>
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton color="primary" sx={{ p: "12px" }} aria-label="directions">
                      <p style={{ fontSize: "12px" }}> MAX </p>
                    </IconButton>
                    <IconButton color="primary" sx={{ p: "12px" }} aria-label="directions">
                      <p style={{ fontSize: "12px" }}> + </p>
                    </IconButton>
                    <IconButton color="primary" sx={{ p: "12px" }} aria-label="directions">
                      <p style={{ fontSize: "12px" }}> - </p>
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton sx={{ p: "12px" }} aria-label="directions">
                      <p style={{ fontSize: "12px" }}> = 0 validators </p>
                    </IconButton>
                  </Paper>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: "20px" }} expanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{ backgroundColor: "#F0AA04" }}
              >
                <Typography style={{ color: "#FFFFFF" }}>Step 2: Withdrawal Credentials</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "14px" }}>
                  <p>
                    Regardless of which option you use it is important to keep access to the private
                    key for this credential as it is the only way you will be able to withdraw your
                    rewards.
                  </p>
                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={6}>
                      <Card
                        className="ethereum"
                        onClick={() => {
                          setEth1Btn(true);
                          setEth2Btn(false);
                          setUseETH1AddressBorder(true);
                          setUseETH2AddressBorder(false);
                          // sx:{{':hover':{background:"#F0AA04"},}}
                        }}
                        style={{ border: `${useETH1AddressBorder ? "1px solid black" : "none"}` }}
                      >
                        <CardActionArea>
                          <CardContent style={{ height: "180px" }}>
                            <Grid container spacing={2}>
                              <Grid xs="1" style={{ marginTop: "40px" }}>
                                <img src={WalletIcon} alt="WalletIcon" height={24} width={24} />
                              </Grid>
                              <Grid xs="7" style={{ marginTop: "40px" }}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  // style={{ color: "#FFFFFF" }}
                                >
                                  Use an ETH1 address
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{ marginLeft: "14px" }}
                            >
                              You can connect your wallet and use your existing ETH account as your
                              withdrawal credentials.
                              <span style={{ fontStyle: "italic" }}>Recommended.</span>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card
                        onClick={() => {
                          setEth2Btn(true);
                          setEth1Btn(false);
                          setUseETH1AddressBorder(false);
                          setUseETH2AddressBorder(true);
                        }}
                        style={{ border: `${useETH2AddressBorder ? "1px solid black" : "none"}` }}
                      >
                        <CardActionArea>
                          <CardContent style={{ height: "180px" }}>
                            <Grid container spacing={2}>
                              <Grid xs="1" style={{ marginTop: "25px" }}>
                                <img src={LedgerIcon} alt="WalletIcon" height={24} width={24} />
                              </Grid>
                              <Grid xs="7" style={{ marginTop: "25px" }}>
                                <Typography gutterBottom variant="h5" component="div">
                                  Use an ETH2 address
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{ marginLeft: "14px" }}
                            >
                              If you already have an ETH2 address you can enter it; connect your
                              Ledger to generate an ETH2 address; or follow Staked`s guide to
                              generate your own ETH2 keypairs offline.
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                  {eth1Btn ? (
                    <TextField
                      error={false}
                      id="outlined-error"
                      // label="Error"
                      placeholder="Enter ETH1 address"
                      fullWidth
                      height={30}
                      style={{ marginTop: "20px" }}
                    />
                  ) : (
                    ""
                  )}
                  {eth2Btn ? (
                    <TextField
                      error={false}
                      id="outlined-error"
                      // label="Error"
                      placeholder="Enter ETH2 withdrawal address"
                      fullWidth
                      height={30}
                      style={{ marginTop: "20px" }}
                    />
                  ) : (
                    ""
                  )}
                  {eth1Btn ? (
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ marginTop: "30px", color: "white", background: "#F0AA04" }}
                    >
                      Connect your wallet
                    </Button>
                  ) : (
                    ""
                  )}
                  {eth2Btn ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{ marginTop: "30px", color: "white", background: "#F0AA04" }}
                        >
                          GENERATE AN ETH2 ADDRESS
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{ marginTop: "30px", color: "white", background: "#F0AA04" }}
                        >
                          OR CONNECT WITH LEDGER
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: "20px" }} expanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="pane3a-header"
                style={{ backgroundColor: "#F0AA04" }}
              >
                <Typography style={{ color: "#FFFFFF" }}>Step 3: Payment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <TextField
                    error={false}
                    id="outlined-error"
                    // label="Error"
                    placeholder="Email Address"
                    fullWidth
                    height={30}
                    style={{ marginTop: "20px" }}
                  />
                  <p style={{ fontSize: "13px", marginLeft: "4px", marginTop: "10px" }}>
                    If paying by crypto, monthly invoices will be directed to this address. Please
                    ensure a valid email address to avoid service interruption.
                  </p>
                  <p style={{ marginTop: "15px", fontSize: "16px" }}>Select payment plan</p>
                  <Card
                    style={{ border: `${monthlyPlanBorder ? "1px solid black" : "none"}` }}
                    onClick={() => {
                      setMonthlyPlanBorder(true);
                      setOneTimeBorder(false);
                    }}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="body1" component="div">
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <img src={CalenderIcon} alt="CalenderIcon" height={24} width={24} />
                            </Grid>
                            <Grid item xs={9}>
                              <p style={{ textAlign: "left" }}>
                                Monthly Plan{" "}
                                <span style={{ position: "relative", float: "right" }}>
                                  $19/m per validator
                                </span>
                              </p>
                              <p style={{ fontSize: "12px" }}>You will be billed monthly.</p>
                            </Grid>
                          </Grid>
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary">
                              <p style={{ fontSize: "12px" }}>You will be billed monthly.</p>
                            </Typography> */}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card
                    style={{
                      border: `${oneTimeBorder ? "1px solid black" : "none"}`,
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setMonthlyPlanBorder(false);
                      setOneTimeBorder(true);
                    }}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="body1" component="div">
                          <Grid container spacing={2}>
                            <Grid item xs={2} />
                            <Grid item xs={9}>
                              <p style={{ textAlign: "left" }}>
                                One-time{" "}
                                <span style={{ position: "relative", float: "right" }}>$299</span>
                              </p>
                              <p style={{ fontSize: "12px" }}>
                                Transfers on ETH2 are disabled until at least Phase 1. A one-time
                                payment will cover your validators until transfers are enabled.
                                Learn more.
                              </p>
                            </Grid>
                          </Grid>
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary">
                              <p style={{ fontSize: "12px" }}>You will be billed monthly.</p>
                            </Typography> */}
                      </CardContent>
                    </CardActionArea>
                  </Card>

                  <p style={{ marginTop: "15px", fontSize: "16px" }}>Select payment method</p>
                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={6}>
                      <Card
                        style={{ border: `${payWithCardBorder ? "1px solid black" : "none"}` }}
                        onClick={() => {
                          setPayWithCryptoBorder(false);
                          setPayWithCardBorder(true);
                        }}
                      >
                        <CardActionArea>
                          <CardContent style={{ height: "120px" }}>
                            <Grid container spacing={2}>
                              <Grid xs="1" style={{ marginTop: "40px" }}>
                                <img src={CardIcon} alt="CardIcon" height={24} width={24} />
                              </Grid>
                              <Grid xs="7" style={{ marginTop: "38px" }}>
                                <Typography gutterBottom variant="h5" component="div">
                                  Pay with card
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{ marginLeft: "14px" }}
                            >
                              Powered by Stripe
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card
                        style={{
                          height: "120px",
                          border: `${payWithCryptoBorder ? "1px solid black" : "none"}`,
                        }}
                        onClick={() => {
                          setPayWithCryptoBorder(true);
                          setPayWithCardBorder(false);
                        }}
                      >
                        <CardActionArea>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              Pay with Crypto
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              ETH, DAI, USDC, Bitcoin, and more Powered by Coinbase Commerce
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: "20px" }} expanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="pane3a-header"
              >
                <Typography>Step 4: Terms</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {/* <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={1} >
                    </Grid>
                     <Grid item xs={1} >
                    </Grid>
                  </Grid> */}
                  <div>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={
                        <Typography component="span" style={{ fontSize: "15px" }}>
                          I understand that depositing ETH1 to the ETH2 beacon chain is irrevocable,
                          and I can neither spend nor{" "}
                          <span>
                            {" "}
                            transfer my ETH2 funds until an unknown date in the future when
                            transfers are enabled on the ETH2 chain.
                          </span>
                        </Typography>
                      }
                    />
                  </div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="I have stored my withdrawal address keypair securely and backed up the mnemonic phrase. I understand that I cannot recover my ETH2 funds if the mnemonic phrase for my withdrawal address is lost or corrupted."
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="I understand that staking involves slashing risks and my funds can be lost."
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="I have read and agree to Staked’s Terms of Service."
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Button
              fullWidth
              variant="contained"
              style={{ marginTop: "30px", color: "white", background: "" }}
            >
              Checkout
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={5} style={{ padding: "20px", backgroundColor: "#F0AA04" }}>
              <Typography gutterBottom variant="h5" component="div" color="#FFFFFF">
                Ethereum 2.0 Staking
              </Typography>
              <Typography
                variant="h8"
                component="div"
                style={{ border: "1px solid black", borderRadius: "5px" }}
              >
                <p
                  style={{
                    padding: "20px",
                    borderBottom: "1px solid black",
                    fontSize: "16px",
                    backgroundColor: "#ededed",
                  }}
                >
                  Current Staking APR{" "}
                  <span style={{ position: "relative", float: "right", color: "#56ac68" }}>
                    4.57%
                  </span>
                </p>
                <p
                  style={{
                    padding: "20px",
                    borderBottom: "1px solid black",
                    fontSize: "16px",
                    backgroundColor: "#ededed",
                  }}
                >
                  Total ETH Staked{" "}
                  <span style={{ position: "relative", float: "right", color: "#468dca" }}>
                    10.23%
                  </span>
                </p>
              </Typography>
              <Typography
                variant="h8"
                component="div"
                style={{ marginTop: "25px", fontSize: "15px", color: "white" }}
              >
                <a href="#top" style={{ color: "white" }}>
                  <b>What is ETH2?</b>
                </a>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default eth2;
