import MDBox from "components/MDBox";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";

// Images
import reward from "../../assets/images/reward.png";

export default function TransationData() {
  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "date", accessor: "date" },
      { Header: "type", accessor: "type" },
      { Header: "asset", accessor: "asset" },
      { Header: "amount", accessor: "amount" },
      { Header: "status", accessor: "status" },
    ],

    rows: [
      {
        id: (
          <Typography component="a" href="#" variant="button" color="text" fontWeight="medium">
            RUIXVH
          </Typography>
        ),
        date: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            04/25/22 <br />
            21:09:46
          </Typography>
        ),
        type: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={reward} size="sm" variant="rounded" sx={{ width: 20, height: 20 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Reward
            </Typography>
          </MDBox>
        ),
        asset: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            Staked Polkadot <br />
            DOT.S
          </Typography>
        ),
        amount: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            0.24173635 <br />
            DOT.S
          </Typography>
        ),
        status: (
          <Typography component="a" href="#" variant="button" color="#008000" fontWeight="medium">
            Success
          </Typography>
        ),
      },
      {
        id: (
          <Typography component="a" href="#" variant="button" color="text" fontWeight="medium">
            RUSOEY
          </Typography>
        ),
        date: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            04/24/22 <br />
            21:36:45
          </Typography>
        ),
        type: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={reward} size="sm" variant="rounded" sx={{ width: 20, height: 20 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Reward
            </Typography>
          </MDBox>
        ),
        asset: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            Staked Cardano <br />
            ADA.S
          </Typography>
        ),
        amount: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            2.069180 <br />
            ADA.S
          </Typography>
        ),
        status: (
          <Typography component="a" href="#" variant="button" color="#008000" fontWeight="medium">
            Success
          </Typography>
        ),
      },
      {
        id: (
          <Typography component="a" href="#" variant="button" color="text" fontWeight="medium">
            RUCVQJ
          </Typography>
        ),
        date: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            04/24/22 <br />
            21:01:54
          </Typography>
        ),
        type: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={reward} size="sm" variant="rounded" sx={{ width: 20, height: 20 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Reward
            </Typography>
          </MDBox>
        ),
        asset: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            Staked Tezos <br />
            XTZ.S
          </Typography>
        ),
        amount: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            0.288129 <br />
            XTZ.S
          </Typography>
        ),
        status: (
          <Typography component="a" href="#" variant="button" color="#008000" fontWeight="medium">
            Success
          </Typography>
        ),
      },
      {
        id: (
          <Typography component="a" href="#" variant="button" color="text" fontWeight="medium">
            RUUYF6
          </Typography>
        ),
        date: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            04/22/22 <br />
            21:04:21
          </Typography>
        ),
        type: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={reward} size="sm" variant="rounded" sx={{ width: 20, height: 20 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Reward
            </Typography>
          </MDBox>
        ),
        asset: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            Staked Solana <br />
            SOL.S
          </Typography>
        ),
        amount: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            0.13105863 <br />
            SOL.S
          </Typography>
        ),
        status: (
          <Typography component="a" href="#" variant="button" color="#008000" fontWeight="medium">
            Success
          </Typography>
        ),
      },
      {
        id: (
          <Typography component="a" href="#" variant="button" color="text" fontWeight="medium">
            RUIGZY
          </Typography>
        ),
        date: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            04/21/22 <br />
            21:09:41
          </Typography>
        ),
        type: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={reward} size="sm" variant="rounded" sx={{ width: 20, height: 20 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Reward
            </Typography>
          </MDBox>
        ),
        asset: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            Staked Polkadot <br />
            DOT.S
          </Typography>
        ),
        amount: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
            0.18110906 <br />
            DOT.S
          </Typography>
        ),
        status: (
          <Typography component="a" href="#" variant="button" color="#008000" fontWeight="medium">
            Success
          </Typography>
        ),
      },
    ],
  };
}
