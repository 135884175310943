/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { Backdrop } from '@material-ui/core';

function Home() {
    const poolData = {
        UserPoolId: "us-east-2_7uRlpy2aY",
        ClientId: "63tb615dko7vgrqj54hms6t650"
    };
    let navigate = useNavigate();
    useEffect(() => {
        if (sessionStorage.getItem("access_token") && sessionStorage.getItem("access_token") !== null) {
            navigate("/dashboard")
        }
    }, []);

    const userPool = new CognitoUserPool(poolData);
    
    const [rememberMe, setRememberMe] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const [errorMessage, setErrorMessage] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorPasswordMessage, setErrorPasswordMessage] = useState(false);

    const handleChange = (prop, event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [emailRequired, setEmailRequired] = useState(false);
    const [passwordRequired, setPasswordRequired] = useState(false);

    const loginHandle = () => {
        setLoading(true);
        let validationArr = [];

        if (values.email) {
            validationArr.push(false);
            setEmailRequired(false);
        } else {
            validationArr.push(true);
            setEmailRequired(true);
        }

        if (values.password) {
            validationArr.push(false);
            setPasswordRequired(false);
        } else {
            validationArr.push(true);
            setPasswordRequired(true);
        }

        if (!validationArr.includes(true)) {
            const user = new CognitoUser({
                Username: values.email,
                Pool: userPool,
            })

            const authDetails = new AuthenticationDetails({
                Username: values.email,
                Password: values.password,
            })

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    console.log("On Success: ", data);
                    sessionStorage.setItem("access_token", data.accessToken.jwtToken);
                    sessionStorage.setItem("refresh_token", data.refreshToken.token);
                    sessionStorage.setItem("email", data.idToken.payload.email);
                    sessionStorage.setItem("subId", data.idToken.payload.sub);
                    navigate("/dashboard");
                    setLoading(false);
                },
                onFailure: err => {
                    console.log("On Error : ", err)
                    console.log("On Error : ", err.message)
                    if (err) {
                        if (err.message.includes("Incorrect username or password.")) {
                            setError(true);
                            setErrorMessage("Incorrect username or password.");
                        } else if (err.message.includes("User is not confirmed.")) {
                            setError(true);
                            setErrorMessage("User is not confirmed.");
                        } else {
                            setError(false);
                            setErrorMessage();
                        }
                    }
                    setLoading(false);
                },
                newPasswordRequired: data => {
                    console.log("New Password Required: ", err)
                    setLoading(false);
                }
            })
        } else {
            setLoading(false);
        }
    };

    return (
        <PageLayout>
            <MDBox
                width="100%"
                mx={2}
                my={2}
                pt={6}
                pb={28}
                sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
                style={{ height: "330px", backgroundColor: "#102039", margin: "0px", padding: "0px", fontFamilly: "_Cardillac,serif" }}
            />
            <MDBox mt={{ xs: -35, lg: -35 }} style={{ textAlign: "center", width: "100%" }} mx="auto">
                <p style={{ color: "white", fontSize: "47px", lineHeight: "50px", fontFamilly: "_Cardillac,serif" }}>Coindelta</p>
                <p style={{ color: "white", fontSize: "38px", fontFamilly: "_Cardillac,serif", marginTop: "40px" }}>Log in</p>
            </MDBox>
            {/* <CoverLayout image={bgImage}> */}
            <MDBox px={1} width="calc(100% - 2rem)" mx="auto">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Backdrop open={loading} style={{ zIndex: 999, color: '#fff' }} >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Card style={{ marginTop: "20px" }}>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <MDBox mb={2}>
                                        {error ?
                                            <Stack sx={{ width: '100%' }} spacing={2}>
                                                <Alert severity="error" style={{ fontSize: "13px" }}><b>{errorMessage}</b></Alert>
                                            </Stack>
                                            : ""}
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput error={emailRequired} type="email" label="Email" variant="standard" value={values.email} fullWidth onChange={(event) => {
                                            handleChange("email", event)
                                        }} />
                                        {passwordRequired ?
                                            <p style={{ fontSize: "12px", color: "red" }}> Email Required </p>
                                            : ""}
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput error={passwordRequired} type="password" label="Password" variant="standard" value={values.password} fullWidth onChange={(event) => {
                                            handleChange("password", event)
                                        }} />
                                        {passwordRequired ?
                                            <p style={{ fontSize: "12px", color: "red" }}> Password Required </p>
                                            : ""}
                                    </MDBox>
                                    <MDBox mt={4} mb={1}>
                                        <MDButton variant="gradient" color="info" fullWidth onClick={() => {
                                            loginHandle();
                                        }}>
                                            sign in
                                        </MDButton>
                                    </MDBox>
                                    <MDBox mt={3} mb={1} textAlign="center">
                                        <MDTypography variant="button" color="text">
                                            <b>Don't have an account?{" "}</b>
                                            <MDTypography
                                                component={Link}
                                                to="/signup"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient

                                            >
                                                Sign Up
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </MDBox>

            {/* </CoverLayout> */}

            {/* <div>
                <p></p>
            </div> */}
            <br />
            <br />
            <br />
            <br />
            {/* <Footer /> */}
        </PageLayout>



    );
}

export default Home;
