import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// import Grid from "@mui/material/Grid";

import InfoIcon from "@mui/icons-material/Info";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as AiIcons from "react-icons/ai";
import * as SiIcons from "react-icons/si";
import * as DiIcons from "react-icons/di";
import * as VscIcons from "react-icons/vsc";
import * as RiIcons from "react-icons/ri";
// import * as GrcIcons from "react-icons/gr";
import * as GrIcons from "react-icons/gr";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Paper, Typography } from "@mui/material";
// import icon from "assets/theme/components/icon";
// import rgba from "assets/theme/functions/rgba";
import Ethereum from "../../../../assets/images/e3.png";
// import { color } from "@mui/system";

function Projects() {
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState("Enter address");
  // const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    console.log("values on Change   ", value);
  }, [value]);

  React.useEffect(() => {
    const jsonEx = {
      id: 2,
      label: "Tezos",
      description: "XTZ",
      text: "Enter XTZ address",
      icon: <IoIcons.IoLogoTumblr />,
      cName: "cart-text",
    };

    console.log(jsonEx);
    const jsonStringyfy = JSON.stringify(jsonEx);
    console.log(jsonStringyfy);
    console.log(JSON.parse(jsonStringyfy));
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    console.log("Hello");
    setOpen(false);
  };
  const listitems = [
    {
      id: 1,
      label: "Ethereum 2",
      description: "ETH2",
      text: "Enter ETH2 address",
      // icon: <img src={Ethereum} alt="etherium" height={20} width={20} />,
      cName: "cart-text",
    },
    {
      id: 2,
      label: "Tezos",
      description: "XTZ",
      text: "Enter XTZ address",
      // icon: <IoIcons.IoLogoTumblr />,
      cName: "cart-text",
    },
    {
      id: 3,
      label: "Livepeer",
      description: "LPT",
      text: "Enter LPT address",
      // icon: <AiIcons.AiOutlineDotChart />,
      cName: "cart-text",
    },
    {
      id: 4,
      label: "Cosmos",
      description: "ATOM",
      text: "Enter ATOM address",
      // icon: <RiIcons.RiReactjsLine />,
      cName: "cart-text",
    },
    {
      id: 5,
      label: "Terra",
      description: "LUNA",
      text: "Enter LUNA address",
      // icon: <SiIcons.SiDblp />,
      cName: "cart-text",
    },
    {
      id: 6,
      label: "Polkadot",
      description: "DOT",
      text: "Enter DOT address",
      // icon: <SiIcons.SiPaypal />,
      cName: "cart-text",
    },
    {
      id: 7,
      label: "Kusama",
      description: "KSM",
      text: "Enter KSM address",
      // icon: <DiIcons.DiSwift />,
      cName: "cart-text",
    },
    {
      id: 8,
      label: "Graph",
      description: "GRT",
      text: "Enter GRT address",
      // icon: <VscIcons.VscGraphLine />,
      cName: "cart-text",
    },
    {
      id: 9,
      label: "Cardano",
      description: "ADA",
      text: "Enter ADA address",
      // icon: <GrIcons.GrCloudlinux />,
      cName: "cart-text",
    },
    {
      id: 10,
      label: "Helium",
      description: "HNT",
      text: "Enter HNT address",
      // icon: <IoIcons.IoLogoSteam />,
      cName: "cart-text",
    },
    {
      id: 11,
      label: "Audius",
      description: "AUDIO",
      text: "Enter AUDIO address",
      // icon: <IoIcons.IoLogoAngular />,
      cName: "cart-text",
    },
    {
      id: 12,
      label: "Near",
      description: "NEAR",
      text: "Enter NEAR address",
      // icon: <IoIcons.IoLogoXing />,
      cName: "cart-text",
    },
    {
      id: 13,
      label: "Flow",
      description: "FLOW",
      text: "Enter FLOW address",
      // icon: <SiIcons.SiKashflow />,
      cName: "cart-text",
    },
    {
      id: 14,
      label: "Skale",
      description: "SKL",
      text: "Enter SKL address",
      // icon: <GrIcons.GrStripe />,
      cName: "cart-text",
    },
    {
      id: 15,
      label: "Solana",
      description: "SOL",
      text: "Enter SOL address",
      // icon: <GrIcons.GrLastfm />,
      cName: "cart-text",
    },
    {
      id: 16,
      label: "Polygon",
      description: "MATIC",
      text: "Enter MATIC address",
      // icon: <GrIcons.GrAggregate />,
      cName: "cart-text",
    },
    {
      id: 17,
      label: "Tron",
      description: "TRX",
      text: "Enter TRX address",
      // icon: <FaIcons.FaUnity />,
      cName: "cart-text",
    },
    {
      id: 18,
      label: "Secret",
      description: "SCRT",
      text: "Enter SCRT address",
      // icon: <FaIcons.FaSpeakap />,
      cName: "cart-text",
    },
  ];
  console.log("list", listitems);

  // console.log("list", holdingicon);
  const holdingicons = new Map();
  console.log(holdingicons);

  //  add values to your hash map

  holdingicons.set("Ethereum 2", <img src={Ethereum} alt="etherium" height={20} width={20} />);
  holdingicons.set("Tezos", <IoIcons.IoLogoTumblr />);
  holdingicons.set("Livepeer", <AiIcons.AiOutlineDotChart />);
  holdingicons.set("Cosmos", <RiIcons.RiReactjsLine />);
  holdingicons.set("Terra", <SiIcons.SiDblp />);
  holdingicons.set("Polkadot", <SiIcons.SiPaypal />);
  holdingicons.set("Kusama", <DiIcons.DiSwift />);
  holdingicons.set("Graph", <VscIcons.VscGraphLine />);
  holdingicons.set("Cardano", <GrIcons.GrCloudlinux />);
  holdingicons.set("Helium", <IoIcons.IoLogoSteam />);
  holdingicons.set("Audius", <IoIcons.IoLogoAngular />);
  holdingicons.set("Near", <IoIcons.IoLogoXing />);
  holdingicons.set("Flow", <SiIcons.SiKashflow />);
  holdingicons.set("Skale", <GrIcons.GrStripe />);
  holdingicons.set("Solana", <GrIcons.GrLastfm />);
  holdingicons.set("Polygon", <GrIcons.GrAggregate />);
  holdingicons.set("Tron", <FaIcons.FaUnity />);
  holdingicons.set("Secret", <FaIcons.FaSpeakap />);

  console.log("holllldingsss", holdingicons);
  console.log(holdingicons.get("Tezos"));
  console.log(holdingicons.size);

  // for ("Enter SCRT address" of holdingicons) {
  //   console.log(holdingicons);
  // }

  return (
    <div>
      {/* <DashboardLayout> */}
      <p style={{ fontSize: "30px" }}>Dashboard</p>
      <Paper
        variant="outlined"
        style={{
          width: `100%`,
          marginTop: "2%",
          padding: "1rem",
          marginBottom: "40px",
        }}
      >
        {/* eslint-disable-next-line */}
        <MDBox
          border="1px solid rgba(0, 0, 0, 0.5)"
          borderColor="#468DC9"
          style={{ backgroundColor: "#F4F9FE" }}
          padding="1rem"
          width="100%"
        >
          <Typography gutterBottom fontSize={12} color="#A095C9">
            <Grid container spacing={2}>
              <Grid
                xs="1"
                marginLeft="20px"
                paddingX="1rem"
                marginTop="15px"
                // color="rgba(70,141,201,var(--tw-text-opacity))"
              >
                {/* <Icon>add</Icon> */}
                <InfoIcon
                  style={{
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                    width: "20px",
                    height: "20px",
                    color: "#468DC9",
                  }}
                />
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="24px"
                  height="24px"
                  classNam="h-5 w-5 mt-0.5 mr-3"
                >
                  <path
                    strokelinecap="round"
                    strokelinejoin="round"
                    strokewidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg> */}
              </Grid>
              <Grid
                xs="10"
                paddingX="1rem"
                marginTop="18px"
                marginLeft="-60px"
                color="#6DBAE8"
                // color="rgba(70,141,201,var(--tw-text-opacity))"
              >
                After staking with or delegating to Staked, add your holdings here to access
                reporting on your assets.
              </Grid>
            </Grid>
          </Typography>
        </MDBox>
      </Paper>
      {/* </DashboardLayout> */}
      <Grid xs="6">
        <Card onClick={handleClickOpen}>
          <MDBox
            // display="flex"
            justifyContent="space-between"
            open={open}
            onClose={handleClose}
            alignItems="center"
            p={5}
            mb={-0.5}
            display="inline-block"
          >
            <MDBox height="200px">
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <Icon
                  sx={{
                    ml: 32,
                    fontWeight: "bold",
                    color: ({ palette: { info } }) => info.main,
                    mt: 8,
                    border: "1px solid",
                    borderRadius: "20px",
                  }}
                >
                  add
                </Icon>
              </MDBox>
              <MDTypography variant="h6" align="center" gutterBottom>
                Add a holding
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {/* eslint-disable-next-line */}
          {"Add A Holding"}
          <MDBox
            border="1px solid rgba(0, 0, 0, 0.5)"
            borderColor="#468DC9"
            padding="1rem"
            style={{ backgroundColor: "#F4F9FE" }}
          >
            <Typography gutterBottom fontSize={12} color="#7AADD9">
              <Grid container spacing={2}>
                <Grid xs="1" marginLeft="10px" paddingX="1rem" marginTop="17px">
                  {/* <Icon>add</Icon> */}
                  <InfoIcon
                    style={{
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                      width: "20px",
                      height: "20px",
                      color: "#468DC9",
                    }}
                  />
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width="24px"
                    height="24px"
                    classNam="h-5 w-5 mt-0.5 mr-3"
                  >
                    <path
                      strokelinecap="round"
                      strokelinejoin="round"
                      strokewidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
                </Grid>
                <Grid xs="10" paddingX="1rem" marginTop="18px" marginLeft="-10px">
                  Adding a holding to your Staked account enables you to access reporting
                  information on your staked assets. Adding a holding here does not delegate or
                  stake. You must first delegate or stake your assets to Staked`s validators on
                  chain, then come back here to add your holding.
                  <br />
                  If you are looking for our Ethereum staking,{" "}
                  {/* <a href="http://localhost:3001/eth2">click here. </a> */}
                  <Link to="/eth2" style={{ color: "#468DC9" }}>
                    click here.{" "}
                  </Link>
                </Grid>
              </Grid>
            </Typography>
            {/* <Typography gutterBottom fontSize={12} color="DodgerBlue">
              If you are looking for our Ethereum staking, click here.
            </Typography> */}
          </MDBox>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Autocomplete
          //  onChange={console.log("onchange is triggered")}
          multiple
          limitTags={1}
          onChange={(options, valueChange) => {
            console.log(valueChange);
            // console.log(JSON.stringify(valueChange));
            setValue(valueChange);
            // console.log(options.target.data-option-index);
          }}
          renderTags={(options) => {
            console.log(options);
            console.log("value of Option Render Tags");
            console.log(value);
            console.log(value.length);
            console.log(value[value.length - 1]);
            return (
              <>
                <Icon>{holdingicons.get(value[value.length - 1].label)}</Icon>
                {value[value.length - 1].label} {value[value.length - 1].description}
              </>
            );
          }}
          // inputValue={inputValue}
          // onInputChange={(options, newInputValue) => {
          //   setInputValue(newInputValue);
          // }}
          // value={this.state.text}
          // onChange={(e) => {
          //   this.state({ text: e.target.value });
          // }}
          // disablePortal
          disableClearable
          id="holdings-select"
          sx={{ width: 550, mb: 5, ml: 2 }}
          options={listitems}
          autoHighlight
          getOptionLabel={(option) => `${option.label}  ${option.description}`}
          //   <Box component="li" {...options}>
          //     <Icon>{options.icon}</Icon>
          //     {options.label} {options.description}
          //   </Box>s
          // )}
          renderOption={(props, option) => (
            <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
              <Icon>{holdingicons.get(option.label)}</Icon> {option.label} {option.description}
            </Box>
          )}
          // onChange={(option) =>
          //   console.log(option.target.label)
          // }
          renderInput={(params) => <TextField {...params} label="Select asset" />}
          // value={text}
          // onChange={(e) => {
          //   ({ text: e.target.value });
          // }}
        />

        <Box
          component="form"
          sx={{
            "& > :not(style)": { mb: 3, mt: -3, ml: 2, width: "49ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            placeholder={
              value && value[value.length - 1].text ? value[value.length - 1].text : "Enter address"
            }
            variant="outlined"
          />
        </Box>
        <Button variant="contained" sx={{ width: 550, color: "#87CEEB", mb: 3, ml: 2 }}>
          ADD HOLDING
        </Button>
        {/* <Autocomplete
          id="Address"
          sx={{ width: 550, mb: 5, ml: 2 }}
          Enter
          address
          renderInput={(params) => <TextField {...params} label="Enter address" />}
        /> */}
        {/* <DialogContent dividers>
          <MDBox py={3}>
            <Grid container spacing={3} onClick={() => console.log("Hello")}>
              {listitems.map((list) => {
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <Icon
                        sx={{
                          align: "center",
                          alignItems: "center",
                          borderRadius: 100,
                          width: 60,
                          height: 60,
                          backgroundColor: "#000",
                          fontWeight: "bold",
                          color: ({ palette: { info } }) => info.main,
                          padding: 2.6,
                          mt: -0.5,
                        }}
                      >
                        {list.icon}
                      </Icon>
                      <Typography
                        gutterBottom
                        fontSize={18}
                        fontWeight="bold"
                        style={{ textAlign: "center" }}
                      >
                        {list.title}
                      </Typography>
                      <Typography
                        gutterBottom
                        fontSize={16}
                        color="darkgrey"
                        style={{ textAlign: "center" }}
                      >
                        {list.description}
                      </Typography>
                    </MDBox>
                  </Grid>
                );
              })}
              ;
            </Grid>
          </MDBox>
        </DialogContent> */}
      </Dialog>
    </div>
  );
}

export default Projects;
