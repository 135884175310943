import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import TransactionInfo from "layouts/Avalanche/TransactionsINFO";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

// Images
import reward from "../../assets/images/reward.png";

function GetCurrentValidator() {
  const [data, setData] = useState(null);
  const [validator, setValidator] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const { columns, rows } = useState();

  let navigate = useNavigate();
  useEffect(() => {
    // if (!sessionStorage.getItem("access_token")) {
    //   navigate("/login");
    // }
  }, []);

  useEffect(() => {}, [validator]);

  useEffect(async () => {
    // POST request using fetch inside useEffect React hook
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "platform.getCurrentValidators",
        params: { nodeIDs: ["NodeID-PoiadeigxryeoPbi2KuSRw2P34k7P7YaZ"] },
        id: "1",
      }),
    };
    const res = await fetch(
      "http://ec2-18-218-170-38.us-east-2.compute.amazonaws.com:9650/ext/P",
      requestOptions
    );
    const json = await res.json();
    if (json) {
      setData(json);
      console.log("json", json.result.validators[0]);
      const row = [];
      json.result.validators[0].delegators.map((del) => {
        console.log("aaaaaaa", del);
        const delegate = {
          id: (
            <Typography component="a" href="#" variant="button" color="text" fontWeight="medium">
              {del.txID}
            </Typography>
          ),
          date: (
            <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
              {/* {console.log(del.startTime)}
              {console.log(new Date(del.startTime))}
              {console.log(moment(new Date(del.startTime * 200)).format("YYYY/MMM/DD"))} */}
              {moment(new Date(del.startTime * 1000)).format("MM/DD/YYYY")}
              {console.log(del.startTime)}
              <br />
              {moment(new Date(del.startTime * 1000)).format("hh:mm:ss")}
            </Typography>
          ),
          type: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <Avatar src={reward} size="sm" variant="rounded" sx={{ width: 20, height: 20 }} />
              <Typography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
              >
                Reward
              </Typography>
            </MDBox>
          ),
          asset: (
            <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
              Staked Avalanche <br />
              AVAX.S
            </Typography>
          ),
          amount: (
            <Typography component="a" href="#" variant="button" color="#000000" fontWeight="medium">
              {del.potentialReward} <br />
              AVAX.S
            </Typography>
          ),
          status: (
            <Typography component="a" href="#" variant="button" color="#008000" fontWeight="medium">
              Success
            </Typography>
          ),
        };
        row.push(delegate);
        return null;
      });
      console.log("row", row);
      setValidator(row);
      setLoading(false);
    } else {
      setLoading(false);
    }
    // fetch("http://ec2-3-132-31-18.us-east-2.compute.amazonaws.com:9650/ext/P", requestOptions)
    //   .then((response) => response.json())
    //   .then((datas) => {
    //     setData(datas);
    //   });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div className="text-center" style={{ marginLeft: "300px", marginTop: "20px" }}>
      <Backdrop open={loading} style={{ zIndex: 999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <p style={{ fontSize: "35px", fontWeight: "bold", marginLeft: "62px", color: "#F77423" }}>
        Node-Info
      </p>
      <Grid container spacing={2} marginLeft="45px">
        <Grid item xs={2} fontWeight="bold">
          StakeAmount:
        </Grid>
        <Grid item xs={2} fontWeight="bold">
          {data && data.result.validators[0].stakeAmount}
        </Grid>
      </Grid>
      <Grid container spacing={2} marginLeft="45px">
        <Grid item xs={2} fontWeight="bold">
          NodeID:
        </Grid>
        <Grid item xs={6} fontWeight="bold">
          {data && data.result.validators[0].nodeID}
        </Grid>
      </Grid>
      <Grid container spacing={2} marginLeft="45px">
        <Grid item xs={2} fontWeight="bold">
          PotentialReward:
        </Grid>
        <Grid item xs={2} fontWeight="bold">
          {data && data.result.validators[0].potentialReward}
        </Grid>
      </Grid>
      <Grid container spacing={2} marginLeft="45px">
        <Grid item xs={2} fontWeight="bold">
          DelegationFee:
        </Grid>
        <Grid item xs={2} fontWeight="bold">
          {data && data.result.validators[0].delegationFee}
        </Grid>
      </Grid>

      <TransactionInfo validator={validator} />
    </div>
  );
}

export default GetCurrentValidator;
