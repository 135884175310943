/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import * as AiFillInfoCircle from "react-icons/ai"
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
import { Typography } from "@mui/material";
// import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DialogTitle from "@mui/material/DialogTitle";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Images
// import masterCardLogo from "assets/images/logos/mastercard.png";
// import visaLogo from "assets/images/logos/visa.png";
// import solana from "../../assets/images/Solana_logo1.png";
// import Ethereum from "../../../../assets/images/e3.png";
import StakingTabledata from "layouts/Reporting/StakingTabledata";
import TransactionTableData from "layouts/Reporting/TransactionsTableData";
import DataTable from "../../examples/Tables/DataTable";

// Material Dashboard 2 React context
// import { useMaterialUIController } from "context";

function StakingTable() {
  const { columns, rows } = StakingTabledata();
  const { columns: pColumns, rows: pRows } = TransactionTableData();
  return (
    <DashboardLayout>
      <Card>
        <MDBox
          mx={2}
          mt={-2}
          py={3}
          px={1}
          // variant="gradient"
          // bgColor="info"
          // borderRadius="lg"
          // coloredShadow="info"
        >
          <Typography variant="h6" color="white">
            Staking assets
          </Typography>
        </MDBox>
        <MDBox pt={-1}>
          <DataTable
            table={{ columns, rows }}
            isSorted="true"
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      </Card>
      <Grid item xs={12} mt={2}>
        <Card>
          <MDBox
            mx={2}
            mt={-2}
            py={3}
            px={1}
            // variant="gradient"
            // bgColor="info"
            // borderRadius="lg"
            // coloredShadow="info"
          >
            <Typography variant="h6" color="white">
              Transations
            </Typography>
          </MDBox>
          <MDBox pt={-1}>
            <DataTable
              table={{ columns: pColumns, rows: pRows }}
              isSorted="true"
              entriesPerPage={false}
              showTotalEntries={false}
              // pageIndex="0"
              // pageSize="5"
              // pageCount="2"
              // canNextPage="true"
              // canPreviousPahe={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </Grid>
    </DashboardLayout>
  );
}

export default StakingTable;
