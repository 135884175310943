/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Metamask from "../../assets/images/metamask.png";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@material-ui/core/Slide';
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const stylePaper = {
    width: "800px",
    maxWidth: "none!important"
};


const buttonStyle = {
    color: "white",
    borderRadius: "20px",
    backgroundColor: "#283944",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "black"
    }

};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

function wallet({ ...props }) {

    const [open, setOpen] = React.useState(false);
    const [whatisWallet, setWhatisWallet] = React.useState(false);
    const [anyWalletSelected, setAnyWalletSelected] = React.useState(false);
    const [walletSelected, setWalletSelected] = React.useState("");

    React.useEffect(() => {
        console.log("wallet Page Model")
        setOpen(props.modalOpen)
    }, [props, open]);
    
    let navigate = useNavigate();
    React.useEffect(() => {
    //   if (!sessionStorage.getItem("access_token")) {
    //     navigate("/login")
    //   }
    }, []);

    React.useEffect(() => {
    }, [whatisWallet, anyWalletSelected]);

    const handleClose = () => {
        setOpen(false);
        props.onChangeModalClose(false);
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} style={{ width: "576px", backgroundColor: "#283944", color: "white" }}>
                    {/* eslint-disable-next-line */}
                    {"Select a Wallet"}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {anyWalletSelected ?
                    <DialogContent style={{ backgroundColor: "#283944", color: "white" }}>
                        <Typography id="modal-modal-description" style={{ fontSize: "12px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>

                                    <img src={Metamask} alt="Metamask" height={30} width={30} /> <span style={{ fontSize: "15px", marginLeft: "10px" }}>MetaMask</span>
                                    <p>You'll need to install MetaMask to continue. Once you have it installed, go ahead and refresh the page.</p>
                                </Grid>

                            </Grid>
                        </Typography>

                        <Button style={{ color: "white" }} onClick={() => {
                            setAnyWalletSelected(false);
                            // setWalletSelected("Metamask");
                        }} >
                            Back
                        </Button>
                        <a href="https://metamask.io/download/" target="_blank">
                            <Button style={{ color: "white", position: "relative", float: "right", border: "2px solid blue" }} onClick={() => {

                            }} >
                                Open Market
                            </Button>
                        </a>
                    </DialogContent>
                    :
                    <DialogContent style={{ backgroundColor: "#283944", color: "white" }}>
                        <Typography id="modal-modal-description" style={{ fontSize: "12px", marginTop: "15px" }}>
                            Please select a wallet to connect to this dapp:
                            <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                <Grid item xs={6}>
                                    <Button fullWidth variant="contained" sx={buttonStyle} style={{ color: "white" }} onClick={() => {
                                        setAnyWalletSelected(true);
                                        // setWalletSelected("Metamask");
                                    }} >
                                        <img src={Metamask} alt="Metamask" height={30} width={30} /> <span style={{ fontSize: "15px", marginLeft: "10px" }}>MetaMask</span>
                                    </Button>
                                </Grid>

                            </Grid>
                        </Typography>

                        <p style={{ marginTop: "25px", fontSize: "16px", color: "blue" }} onClick={() => {
                            setWhatisWallet(whatisWallet ? false : true);
                        }}><b>What is a wallet?</b></p>
                        {whatisWallet ?
                            <p style={{ marginTop: "25px", fontSize: "15px" }}>Wallets are used to send, receive, and store digital assets like Ether. Wallets come in many forms. They are either built into your browser, an extension added to your browser, a piece of hardware plugged into your computer or even an app on your phone. For more information about wallets, see this explanation.</p>
                            : ""}
                    </DialogContent>
                }
            </Dialog>

        </div>
    );
}

export default wallet;
