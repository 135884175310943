import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "../../examples/Tables/DataTable";

function TransactionInfo(props) {
  const [rows, setRows] = useState([]);
  const columns = [
    { Header: "id", accessor: "id" },
    { Header: "date", accessor: "date" },
    { Header: "type", accessor: "type" },
    { Header: "asset", accessor: "asset" },
    { Header: "amount", accessor: "amount" },
    { Header: "status", accessor: "status" },
  ];
  useEffect(() => {
    /* eslint-disable react/prop-types */
    const { validator } = props; /* eslint-disable react/prop-types */
    setRows(validator);
  }, [props]);
  return (
    <DashboardLayout>
      <Card style={{ marginLeft: "-300px" }}>
        <MDBox
          mx={2}
          mt={-2}
          py={3}
          px={1}
          // variant="gradient"
          // bgColor="info"
          // borderRadius="lg"
          // coloredShadow="info"
        >
          <Typography variant="h6" color="white">
            Transactions
          </Typography>
        </MDBox>
        <MDBox pt={-1}>
          <DataTable
            table={{ columns, rows }}
            isSorted="true"
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default TransactionInfo;
