/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import InfoIcon from "@mui/icons-material/Info";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
// import DialogTitle from "@mui/material/DialogTitle";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import Dashboard from "layouts/dashboard";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Images
// import masterCardLogo from "assets/images/logos/mastercard.png";
// import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
// import { useMaterialUIController } from "context";

function Monitor() {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  return (
    <DashboardLayout>
      <p style={{ fontSize: "30px" }}>Ethereum Validators</p>
      <Paper
        variant="outlined"
        style={{
          width: `100%`,
          marginLeft: `0%`,
          marginTop: "2%",
          padding: "1rem",
        }}
      >
        {/* eslint-disable-next-line */}
        <MDBox
          border="1px solid rgba(0, 0, 0, 0.5)"
          borderColor="#468DC9"
          padding="1rem"
          style={{ backgroundColor: "#F4F9FE" }}
        >
          <Typography gutterBottom fontSize={12} color="#A095C9">
            <Grid container spacing={2}>
              <Grid xs="1" marginLeft="8px" paddingX="1rem" marginTop="17px">
                {/* <Icon>add</Icon> */}
                <InfoIcon
                  style={{
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                    width: "20px",
                    height: "20px",
                    color: "#468DC9",
                  }}
                />
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="24px"
                  height="24px"
                  classNam="h-5 w-5 mt-0.5 mr-3"
                >
                  <path
                    strokelinecap="round"
                    strokelinejoin="round"
                    strokewidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg> */}
              </Grid>
              <Grid xs="11" paddingX="1rem" marginTop="18px" marginLeft="-60px" color="#5D8DD0">
                We couldn`t find any Ethereum validators associated with this Staked account. If you
                believe this an error, please check that you are logged in with the correct account
                then contact support. Or,{" "}
                <Link to="/eth2" style={{ color: "#5D8DD0" }}>
                  click here{" "}
                </Link>
                to stake Ethereum.
              </Grid>
            </Grid>
          </Typography>
        </MDBox>
      </Paper>
    </DashboardLayout>
  );
}

export default Monitor;
