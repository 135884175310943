// import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
// import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

//  Images
import solana from "../../assets/images/Solana_logo1.png";
import polkadot from "../../assets/images/polkadot.png";
import cardano from "../../assets/images/cardano.png";
import tezos from "../../assets/images/tezos.png";
import kusama from "../../assets/images/kusama.png";

export default function StakingData() {
  return {
    columns: [
      { Header: "asset", accessor: "asset" },
      { Header: "*reward %", accessor: "reward" },
      { Header: "total rewards", accessor: "totalrewards" },
      { Header: "staking balance", accessor: "stakingbalance" },
      { Header: "value", accessor: "value" },
      { Header: "action", accessor: "action" },
    ],

    rows: [
      {
        asset: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={solana} size="sm" variant="rounded" sx={{ width: 24, height: 24 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Solana <br />
              SOL
            </Typography>
          </MDBox>
        ),
        reward: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            6-8%
          </Typography>
        ),
        totalrewards: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            4.49343000 <br />
            SOL
          </Typography>
        ),
        stakingbalance: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            114.10880000 <br />
            SOL
          </Typography>
        ),
        value: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            11,249.99 <br />
            USD
          </Typography>
        ),
        action: (
          // <Icon src={image} name={name} size="sm" variant="rounded" />
          <>
            <FileDownloadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
            <FileUploadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
          </>
        ),
      },
      {
        asset: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={polkadot} size="sm" variant="rounded" sx={{ width: 24, height: 24 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Polkadot <br />
              DOT
            </Typography>
          </MDBox>
        ),
        reward: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            12.00%
          </Typography>
        ),
        totalrewards: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            13.45756759 <br />
            DOT
          </Typography>
        ),
        stakingbalance: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            184.20656759 <br />
            DOT
          </Typography>
        ),
        value: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            3,107.42 <br />
            USD
          </Typography>
        ),
        action: (
          // <Icon src={image} name={name} size="sm" variant="rounded" />
          <>
            <FileDownloadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
            <FileUploadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
          </>
        ),
      },
      {
        asset: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={cardano} size="sm" variant="rounded" sx={{ width: 24, height: 24 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Cardano <br />
              ADA
            </Typography>
          </MDBox>
        ),
        reward: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            4-6%
          </Typography>
        ),
        totalrewards: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            91.254586 <br />
            ADA
          </Typography>
        ),
        stakingbalance: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            2,701.307486 <br />
            ADA
          </Typography>
        ),
        value: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            2,272.39 <br />
            USD
          </Typography>
        ),
        action: (
          // <Icon src={image} name={name} size="sm" variant="rounded" />
          <>
            <FileDownloadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
            <FileUploadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
          </>
        ),
      },
      {
        asset: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={tezos} size="sm" variant="rounded" sx={{ width: 24, height: 24 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Tezos <br />
              XTS
            </Typography>
          </MDBox>
        ),
        reward: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            5.00%
          </Typography>
        ),
        totalrewards: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            33.898919 <br />
            XTZ
          </Typography>
        ),
        stakingbalance: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            526.487499 <br />
            XTZ
          </Typography>
        ),
        value: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            1,480.96 <br />
            USD
          </Typography>
        ),
        action: (
          // <Icon src={image} name={name} size="sm" variant="rounded" />
          <>
            <FileDownloadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
            <FileUploadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
          </>
        ),
      },
      {
        asset: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Avatar src={kusama} size="sm" variant="rounded" sx={{ width: 24, height: 24 }} />
            <Typography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
              Kusama <br />
              KSM
            </Typography>
          </MDBox>
        ),
        reward: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            18.00%
          </Typography>
        ),
        totalrewards: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            0.00000000 <br />
            KSM
          </Typography>
        ),
        stakingbalance: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            0.00000000 <br />
            KSM
          </Typography>
        ),
        value: (
          <Typography component="a" href="#" variant="button" color="#000000" fontWeight="bold">
            0.00 <br />
            USD
          </Typography>
        ),
        action: (
          // <Icon src={image} name={name} size="sm" variant="rounded" />
          <>
            <FileDownloadOutlinedIcon sx={{ width: 20, height: 20, color: "blue" }} />
            <FileUploadOutlinedIcon sx={{ width: 20, height: 20 }} />
          </>
        ),
      },
    ],
  };
}
