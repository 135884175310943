/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import Box from '@mui/material/Box';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea } from '@mui/material';
import { RiVipDiamondFill } from 'react-icons/fa';
import RightChervon from "../../assets/images/rightChervon.svg"
import ETH2ICON from "../../assets/images/ETH2.png"
import SolanaICON from "../../assets/images/solana.png"
import CardanoLogo from "../../assets/images/CardanoLogo.png"
import { CanvasJSChart } from 'canvasjs-react-charts'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        backgroundColor: "#102039",
        color: "white",
    };
}

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

function Home() {
    const [rememberMe, setRememberMe] = useState(false);
    const [value, setValue] = useState(0);
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const [eth2MouseHover, setEth2MouseHover] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];

    const options = {
        animationEnabled: true,
        axisX: {
            valueFormatString: "MMM"
        },
        data: [{
            yValueFormatString: "$#,###",
            xValueFormatString: "MMMM",
            type: "spline",
            dataPoints: [
                { x: new Date(2017, 0), y: 1 },
                { x: new Date(2017, 1), y: 5 },
                { x: new Date(2017, 2), y: 4 },
                { x: new Date(2017, 3), y: 7 },
                { x: new Date(2017, 4), y: 8 },
                { x: new Date(2017, 5), y: 9 },
                { x: new Date(2017, 6), y: 10 },
                { x: new Date(2017, 7), y: 7 },
                { x: new Date(2017, 8), y: 11 },
                { x: new Date(2017, 9), y: 14 },
                { x: new Date(2017, 10), y: 16 },
                { x: new Date(2017, 11), y: 20 }
            ]
        }]
    }

    return (
        <PageLayout>
            <DefaultNavbar
                action={{
                    type: "external",
                    route: "https://creative-tim.com/product/material-dashboard-react",
                    label: "free download",
                }}
                transparent
                light
            />
            <MDBox
                width="100%"
                // minHeight={coverHeight}
                // borderRadius="xl"
                mx={2}
                my={2}
                pt={6}
                pb={28}
                sx={{
                    // backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    //   image &&
                    //   `${linearGradient(
                    //     rgba(gradients.dark.main, 0.4),
                    //     rgba(gradients.dark.state, 0.4)
                    //   )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
                style={{ height: "660px", backgroundColor: "#102039", margin: "0px", padding: "0px", fontFamilly: "_Cardillac,serif" }}
            />
            <MDBox mt={{ xs: -30, lg: -30 }} px={1} width="calc(100% - 2rem)" mx="auto">
                <p style={{ color: "white", position: "absolute", top: "20%", left: "33%", fontSize: "47px", lineHeight: "50px", fontFamilly: "_Cardillac,serif" }}>Fixed Income Solutions <br /> <span style={{ marginLeft: "-30px    " }}>for Crypto Asset Investors</span></p>
                <p style={{ color: "white", position: "absolute", top: "39%", left: "34%", fontSize: "20px", fontFamilly: "_Cardillac,serif" }}>Staked helps investors earn yield from staking and <br /> DeFi without taking custody of their crypto assets.</p>
            </MDBox>
            <MDBox mt={{ xs: -30, lg: -30 }} px={1} width="calc(100% - 2rem)" mx="auto">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                            <Grid item xs={4}>
                                <Paper style={{ height: "430px" }} variant="outlined" >
                                    <div style={{ height: "25%", backgroundColor: "#f77423" }}>
                                    </div>
                                    <div>
                                        <p style={{ marginTop: "30px", padding: "15px" }}>Crypto & Venture Funds</p>
                                        <p style={{ marginTop: "-20px", padding: "15px" }}>The Staking Partner of Choice for Institutional Crypto</p>
                                        <div style={{ marginTop: "30px", padding: "30px" }}>
                                            <p style={{ padding: "15px", paddingLeft: "30px", paddingRight: "30px", border: "1px solid black", float: "left" }}> Get Started </p>
                                            <p style={{ float: "right", marginRight: "23%", padding: "10px", border: "1px solid", paddingBottom: "9px" }}>
                                                <img src={RightChervon} alt="RightChervon" height={24} width={24} style={{ marginLeft: "24px", marginRight: "27px", marginTop: "10px" }} />
                                            </p>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper style={{ height: "430px" }} variant="outlined" >
                                    <div style={{ height: "25%", backgroundColor: "#4fa988" }}>
                                    </div>
                                    <div>
                                        <p style={{ marginTop: "63px", padding: "15px" }}>Exchanges & Custodians</p>
                                        <p style={{ marginTop: "-20px", padding: "15px" }}>Infrastructure to Power Staking</p>
                                        <div style={{ marginTop: "30px", padding: "30px" }}>
                                            <p style={{ padding: "15px", paddingLeft: "30px", paddingRight: "30px", border: "1px solid black", float: "left" }}> Get Started </p>
                                            <p style={{ float: "right", marginRight: "23%", padding: "10px", border: "1px solid", paddingBottom: "9px" }}>
                                                <img src={RightChervon} alt="RightChervon" height={24} width={24} style={{ marginLeft: "24px", marginRight: "27px", marginTop: "10px" }} />
                                            </p>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper style={{ height: "430px" }} variant="outlined" >
                                    <div style={{ height: "25%", backgroundColor: "#515fcb" }}>
                                    </div>
                                    <div>
                                        <p style={{ marginTop: "30px", padding: "15px" }}>Traditional Investors & Asset Allocators</p>
                                        <p style={{ marginTop: "-20px", padding: "15px" }}>A familiar structure for ETH exposure plus a staking yield</p>
                                        <div style={{ marginTop: "30px", padding: "30px" }}>
                                            <p style={{ padding: "15px", paddingLeft: "30px", paddingRight: "30px", border: "1px solid black", float: "left" }}> Get Started </p>
                                            <p style={{ float: "right", marginRight: "23%", padding: "10px", border: "1px solid", paddingBottom: "9px" }}>
                                                <img src={RightChervon} alt="RightChervon" height={24} width={24} style={{ marginLeft: "24px", marginRight: "27px", marginTop: "10px" }} />
                                            </p>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </MDBox>
            <div>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={4} />
                    <Grid item xs={5}>
                        <p justifyContent="center" style={{ marginTop: "50px", fontSize: "45px" }}>Join the <b>Industry Leaders</b></p>
                    </Grid>
                    <Grid item xs={3} />
                </Grid>

                <Grid container spacing={1} style={{ height: "630px", backgroundColor: "#102039", fontFamilly: "_Cardillac,serif", color: "white" }}>
                    <Grid item xs={1} />
                    <Grid item xs={2}  >
                        <p style={{ marginTop: "50px", fontSize: "35px" }}>Asset</p>
                        <Box
                            sx={{ flexGrow: 1, display: 'flex', height: 125, width: 150, backgroundColor: "#102039" }}
                        >
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="icon position tabs example"
                                style={{ backgroundColor: "#102039", width: "100%", color: "white" }}
                            // sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Staking" {...a11yProps(0)} style={{ color: "white" }} />
                                <Tab label="Coming Soon" {...a11yProps(1)} style={{ color: "white" }} />
                            </Tabs>

                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <TabPanel value={value} index={0} style={{ backgroundColor: "#102039", fontFamilly: "_Cardillac,serif", color: "white" }}>
                            <Grid container spacing={1} style={{ marginTop: "50px" }} >
                                <Grid item xs={4} >
                                    <Paper variant="outlined" style={{ backgroundColor: "#102039", fontFamilly: "_Cardillac,serif", color: "white", padding: "16px", border: "2px solid rgba(33,143,206,.3)" }} >
                                        <img src={ETH2ICON} alt="ETH2ICON" height={60} width={60} style={{ float: "left" }} />
                                        <div style={{ marginLeft: "35px" }}>
                                            <p style={{ fontSize: "20px", marginLeft: "35px" }}><b>ETH 2.0</b></p>
                                            <p style={{ marginLeft: "35px" }}>ETH</p>
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} >
                                    <Paper variant="outlined" style={{ backgroundColor: "#102039", fontFamilly: "_Cardillac,serif", color: "white", padding: "16px", border: "2px solid rgba(33,143,206,.3)" }} onMouseOver={() => {
                                        console.log("Paper Mouse Hover")
                                        setEth2MouseHover(true);
                                    }} onMouseOut={() => {
                                        console.log("Paper Mouse Hover Out")
                                        setEth2MouseHover(false);
                                    }} >
                                        <img src={SolanaICON} alt="SolanaICON" height={60} width={60} style={{ float: "left" }} />
                                        <div style={{ marginLeft: "35px" }}>
                                            <p style={{ fontSize: "20px", marginLeft: "35px" }}><b>Solana</b></p>
                                            <p style={{ marginLeft: "35px" }}>SOL</p>
                                        </div>
                                        {eth2MouseHover ?
                                            <div style={{ marginTop: "-40px", marginLeft: "105px" }}>
                                                <Button variant="contained"> Stake </Button>
                                            </div>
                                            : ""}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} >
                                    <Paper variant="outlined" style={{ backgroundColor: "#102039", fontFamilly: "_Cardillac,serif", color: "white", padding: "16px", border: "2px solid rgba(33,143,206,.3)" }} >
                                        <img src={CardanoLogo} alt="CardanoLogo" height={60} width={60} style={{ float: "left" }} />
                                        <div style={{ marginLeft: "35px" }}>
                                            <p style={{ fontSize: "20px", marginLeft: "35px" }}><b>ETH 2.0</b></p>
                                            <p style={{ marginLeft: "35px" }}>ETH</p>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <p>Not Available</p>
                        </TabPanel>

                    </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={4} />
                    <Grid item xs={5}>
                        <p justifyContent="center" style={{ marginTop: "50px", fontSize: "45px", color: "black", lineHeight: "50px", color: "#102039" }}><b>Crypto’s Trusted Partner of <br /><span style={{ marginLeft: "22px" }}>Choice for ETH2 Staking</span></b></p>
                        <p justifyContent="center" style={{ marginTop: "20px", color: "#102039", fontSize: "15px" }}>Staked’s robust suite of ETH2 infrastructure and tooling make it easy for large ETH holders, institutional investors, custodians and exchanges to participate in ETH2 staking.</p>
                        <Button
                            justifyContent="center"
                            variant="contained"
                            style={{ marginTop: "30px", color: "white", background: "blue", padding: "15px", marginLeft: "35%", paddingLeft: "30px", paddingRight: "30px" }}
                        >
                            Stake Now
                        </Button>
                    </Grid>
                    <Grid item xs={3} />
                </Grid>


                <Grid container spacing={1} justifyContent="center" style={{ marginTop: "30px" }}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <CanvasJSChart options={options}
                        /* onRef={ref => this.chart = ref} */
                        />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dessert (100g serving)</TableCell>
                                        <TableCell align="right">Calories</TableCell>
                                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">{row.carbs}</TableCell>
                                            <TableCell align="right">{row.protein}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>



                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={4} />
                    <Grid item xs={5}>
                        <p justifyContent="center" style={{ marginTop: "50px", fontSize: "45px", color: "black", lineHeight: "50px", color: "#102039" }}><b>Why Choose Staked?</b></p>
                    </Grid>
                    <Grid item xs={3} />
                </Grid>
            </div>
            {/* <div>
                <p></p>
            </div> */}
            <br />
            <br />
            <br />
            <br />
            {/* <Footer /> */}
        </PageLayout>



    );
}

export default Home;
