import React from "react";
import config from "../config";

export default class AvalancheApiService extends React.Component {
  getNodeInfo = async () => {
    return fetch(config.api.BASE_URL + "/api/getAllNodes", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getValidatorsByNodeId = async (nodeId) => {
    return fetch(config.api.BASE_URL + "/api/getValidatorRewards/" + nodeId, {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getAllBlockchains = async () => {
    return fetch(config.api.BASE_URL + "/api/getBlockchains", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getCurrentSupplyByNodeId = async (nodeId) => {
    return fetch(config.api.BASE_URL + "/api/getCurrentSupply/" + nodeId, {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getAvaScanInfo = async () => {
    return fetch(config.api.BASE_URL + "/api/getAvaScanInfo", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getTotalStakedByNodeId = async (nodeId) => {
    return fetch(config.api.BASE_URL + "/api/getTotalStaked/" + nodeId, {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getBlockchainStatusByBlockchainId = async (blockchainId) => {
    return fetch(config.api.BASE_URL + "/api/getBlockchainStatus?blockchain=" + blockchainId, {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getAllCurrentSupply = async () => {
    return fetch(config.api.BASE_URL + "/api/getAllCurrentSupply", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getAllBlockchainStatus = async () => {
    return fetch(config.api.BASE_URL + "/api/getAllBlockchainStatus", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getAllTotalStaked = async () => {
    return fetch(config.api.BASE_URL + "/api/getAllTotalStaked", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getTransactionHistory = async () => {
    return fetch("https://avascan.info/react-api/network/1/home/stats/txs/history/2/days?", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getBurnedFees24H = async () => {
    return fetch("https://api.avascan.info/burned/c/ts?format=json&tf=1d", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getTransactionalHistory = async () => {
    return fetch(config.api.BASE_URL + "/api/getTransactionalHistory", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getStatsTransactionAvax = async () => {
    return fetch("https://avascan.info/react-api/network/1/home/stats/txs/latest?", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  getStatsBlocksAvax = async () => {
    return fetch("https://avascan.info/react-api/network/1/home/stats/blocks/latest?", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

}
