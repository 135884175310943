/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import { useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Wallet from '../wallet/index.js'
import { useNavigate } from "react-router-dom";

function Overview() {

  const [modalOpen, setModalOpen] = React.useState(false);
  let navigate = useNavigate();
  React.useEffect(() => {

  }, [modalOpen])

  useEffect(() => {
    // if (!sessionStorage.getItem("access_token")) {
    //   navigate("/login")
    // }
  }, []);
  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <p style={{ fontSize: "30px" }}>Account</p>
            {/* <Box
              sx={{
                display: 'flex',
                '& > :not(style)': {
                  m: 1,
                  width: "100%",
                  height: "100%",
                },
              }}
            > */}
            <Paper variant="outlined" style={{ padding: "15px" }}>
              <p>Logging In</p>
              <p style={{ fontSize: "15px", marginLeft: "20px" }}>You can log in to Staked with either a traditional email and password or by using a Web3 wallet such as Metamask and signing a challenge to prove your identity.</p>

              <div>
                <p style={{ fontSize: "16px", marginTop: "12px" }}><b>Email</b></p>
                <p style={{ position: "relative", float: "right", top: "-25px", fontSize: "17px" }}>{sessionStorage.getItem("email")}
                  <Button variant="contained" style={{ color: "white", background: "#228fce", marginLeft: "15px" }}>
                    Change
                  </Button></p>
                <p style={{ fontSize: "15px", marginLeft: "20px" }}>Use this email to sign in to Staked and recieve account updates and optional statement emails.</p>
              </div>

              <div style={{ marginTop: "15px" }}>
                <p style={{ fontSize: "16px" }}><b>Password</b></p>
                <Button variant="contained" style={{ position: "relative", float: "right", top: "-25px", color: "white", background: "#228fce", marginLeft: "15px" }}>
                  Change
                </Button>
                <p style={{ fontSize: "15px", marginLeft: "20px" }}>Update your password.</p>
              </div>
              <div style={{ marginTop: "15px" }}>
                <p style={{ fontSize: "16px" }}><b>Use a Web3 wallet to login</b></p>
                <Button variant="contained" style={{ position: "relative", float: "right", top: "-25px", color: "white", background: "#228fce", marginLeft: "15px", width: "40%" }}
                  onClick={() => {
                    console.log("button On Click")
                    setModalOpen(true)
                  }} >
                  LINK WEB3 WALLET
                </Button>
                <p style={{ fontSize: "15px", marginLeft: "20px" }}>If you link a Web3 wallet such as Metamask to your account, you can choose to access your Staked account with either your email and password or by signing with your wallet to prove your identity.</p>
              </div>

              <Wallet modalOpen={modalOpen} onChangeModalClose={(value) => {
                console.log("onChangeModalClose from child")
                setModalOpen(value);
              }} />
            </Paper>
            {/* </Box> */}
            <Button fullWidth variant="outlined" color="error" style={{ marginTop: "30px", color: "red", border: "1px solid red", padding: "15px", fontSize: "16px" }}
              onClick={() => {
                sessionStorage.removeItem("access_token");
                sessionStorage.removeItem("refresh_token");
                sessionStorage.removeItem("email");
                sessionStorage.removeItem("subId");
                navigate("/login");
              }}>
              Logout
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Box>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;

