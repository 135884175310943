/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, forwardRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import PageLayout from "examples/LayoutContainers/PageLayout";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useNavigate } from "react-router-dom";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Home() {
    const poolData = {
        UserPoolId: "us-east-2_7uRlpy2aY",
        ClientId: "63tb615dko7vgrqj54hms6t650"
    };

    const userPool = new CognitoUserPool(poolData);
    let navigate = useNavigate();
    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const [passwordError, setPasswordError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [errorPasswordMessage, setErrorPasswordMessage] = useState(false);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert(false);
    };

    useEffect(() => { }, [errorPasswordMessage, passwordError]);

    const onSubmit = event => {

        setLoading(true);
        let validationArr = [];

        if (values.email) {
            validationArr.push(false);
            setMailError(false);
        } else {
            validationArr.push(true);
            setMailError(true);
            setErrorPasswordMessage("Email is Required");
        }

        if (values.password) {
            validationArr.push(false);
            setPasswordError(false);
        } else {
            validationArr.push(true);
            setPasswordError(true);
            setErrorPasswordMessage("Email is Required");
        }

        if (!validationArr.includes(true)) {

            userPool.signUp(values.email, values.password, [], null, (err, data) => {

                console.log(data);
                if (data && data.user && data.userSub) {
                    setAlert(true);
                    setTimeout(() => {
                        navigate("/confirm");
                    }, 1000)
                }

                if (err) {
                    console.log(err);
                    console.log(err.message);
                    if (err.message.includes("Password must have numeric characters")) {
                        setPasswordError(true);
                        setErrorPasswordMessage("Password must have numeric characters");
                    } else if (err.message.includes("Password must have uppercase characters")) {
                        setPasswordError(true);
                        setErrorPasswordMessage("Password must have uppercase characters");
                    } else if (err.message.includes("Password must have lowercase characters")) {
                        setPasswordError(true);
                        setErrorPasswordMessage("Password must have lowercase characters");
                    } else if (err.message.includes("Password must have symbol characters")) {
                        setPasswordError(true);
                        setErrorPasswordMessage("Password must have symbol/special characters");
                    } else {
                        setPasswordError(false);
                    }

                    if (err.message.includes("Username should be an email")) {
                        setMailError(true);
                        setErrorPasswordMessage("Username should be an email");
                    } else if (err.message.includes("An account with the given email already exists")) {
                        setMailError(true);
                        setErrorPasswordMessage("Email already exists");
                    } else {
                        setMailError(false);
                    }
                }
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    const handleChange = (prop, event) => {
        if (prop === 'termsAndCondition') {
            setValues({ ...values, [prop]: event.target.checked });
        } else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };
    return (
        <PageLayout>

            <MDBox
                width="100%"
                mx={2}
                my={2}
                pt={6}
                pb={28}
                sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
                style={{ height: "330px", backgroundColor: "#102039", margin: "0px", padding: "0px", fontFamilly: "_Cardillac,serif" }}
            />
            <MDBox mt={{ xs: -35, lg: -35 }} px={1} style={{ textAlign: "center", width: "100%" }} mx="auto">
                <p style={{ color: "white", fontSize: "47px", lineHeight: "50px", fontFamilly: "_Cardillac,serif" }}>Coindelta</p>
                <p style={{ color: "white", fontSize: "38px", fontFamilly: "_Cardillac,serif", marginTop: "40px" }}>Sign up</p>
            </MDBox>
            {/* <CoverLayout image={bgImage}> */}
            <MDBox px={1} width="calc(100% - 2rem)" mx="auto">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Snackbar open={alert} autoHideDuration={1000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                Signup Success.
                            </Alert>
                        </Snackbar>
                        <Backdrop open={loading} style={{ zIndex: 999, color: '#fff' }} >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Card style={{ marginTop: "20px" }}>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <MDBox mb={2} />

                                    <MDBox mb={2}>
                                        <MDInput error={mailError} type="email" label="Email" variant="standard" value={values.email} fullWidth onChange={(event) => {
                                            handleChange("email", event)
                                        }} />
                                        {mailError ?
                                            <p style={{ fontSize: "12px", color: "red" }}>{errorPasswordMessage}</p>
                                            : ""}
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput error={passwordError} type="password" label="Password" variant="standard" value={values.password} fullWidth onChange={(event) => {
                                            handleChange("password", event)
                                        }} />
                                        {passwordError ?
                                            <p style={{ fontSize: "12px", color: "red" }}>{errorPasswordMessage}</p>
                                            : ""}
                                    </MDBox>
                                    <MDBox mt={4} mb={1}>
                                        <MDButton variant="gradient" color="info" fullWidth onClick={() => {
                                            console.log("Sign In Cutton Clicked");
                                            onSubmit();
                                        }}>
                                            sign Up
                                        </MDButton>
                                    </MDBox>
                                    <MDBox mt={3} mb={1} textAlign="center">
                                        <MDTypography variant="button" color="text">
                                            <b>Already have an account?{" "}</b>
                                            <MDTypography
                                                component={Link}
                                                to="/login"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                Sign in
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </MDBox>

            {/* </CoverLayout> */}

            {/* <div>
                <p></p>
            </div> */}
            <br />
            <br />
            <br />
            <br />
            {/* <Footer /> */}
        </PageLayout>



    );
}

export default Home;
